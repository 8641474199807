import React, { useContext } from 'react'
import { FieldErrors } from 'react-hook-form/dist/types'
import { FormattedMessage } from 'react-intl'
import AppContext from '../../context/context'
import Error from '../error/Error'
import InputCesta from '../inputCesta/InputCesta'
import { loginFields } from './fieldValidations'
import styles from './index.module.scss'

type LoginFields = { [k in keyof typeof loginFields]: string }

type ILoginForm = LoginFields & {
  checkboxPrivacy: string
  checkboxSpam: string
}

export type LoginFormErrores = FieldErrors<ILoginForm>

interface Props {
  onLogin: () => void
  preFillEmail?: string
  formRef: any
  errors: LoginFormErrores
  setStep: (step: number) => void
}
const LoginForm = ({ preFillEmail, formRef, errors, setStep }: Props) => {
  const { userInfo } = useContext(AppContext)
  return (
    <React.Fragment>
      <InputCesta
        label={<FormattedMessage id="login.email" />}
        defaultValue={preFillEmail}
        name="email"
        inputSize="big"
        type="text"
        refe={formRef}
        errors={errors}
      />
      <InputCesta
        label={<FormattedMessage id="login.password" />}
        name="password"
        type="password"
        refe={formRef}
        errors={errors}
      />
      {userInfo.error && (
        <Error errorClass={styles.error} message={userInfo.error}></Error>
      )}
      <p className={styles.recover_pass}>
        <FormattedMessage id="validations.login.passwordChange" />{' '}
        <a className={styles.bold} href="#" onClick={() => setStep(1)}>
          <FormattedMessage id="validations.login.passwordClick" />
        </a>
      </p>
    </React.Fragment>
  )
}

export default LoginForm
