import * as yup from 'yup'
import { t } from '../../../common/i18n'

const loginFields = {
  email: yup
    .string()
    .email(t('validations.register.valid_email'))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
  password: yup
    .string()
    .required(
      t('validations.register.required', { label: t('formLabels.password') })
    )
    .min(6, t('validations.register.passwordLength')),
}

export { loginFields }
