import { navigate, graphql } from 'gatsby'
import React, { useState } from 'react'
import FunnelSideBar from '../../components/funnelSideBar/FunnelSideBar'
import Layout from '../../components/Layout'
import LayoutSelector from '../../components/layouts/layoutSelector/LayoutSelector'
import LoginForm from '../../components/loginForm/LoginForm'
import Tabs from '../../components/tabs/Tabs'
import { t } from '../../i18n'
import route from '../../utils/route'
import styles from './index.module.scss'
import LoginContainer from '../../components/loginForm/LoginContainer'
import { SeoData } from '../../types/Seo'

const tabsArray = [
  { tab: t('steps.signin'), route: route('register.index'), active: false },
  { tab: t('steps.login'), route: route('login.index'), active: true },
]

interface Props {
  data: {
    seoData: SeoData
  }
}

const Login = ({ data }: Props) => (
  <Layout
    seoData={data.seoData}
    onGoBack={() => navigate(route('index'))}
    selector={true}>
    <LayoutSelector
      hideSelectorSteps={true}
      title={t('steps.area_clientes')}
      subTitle="Login">
      <div className={styles.login_form_container}>
        <Tabs tabsArray={tabsArray} />
        <div className={styles.login_wrapper}>
          <LoginContainer
            onLogin={() => {
              navigate(route('area-privada.index'), { replace: true })
            }}
          />
        </div>
      </div>
      <FunnelSideBar />
    </LayoutSelector>
  </Layout>
)

export default Login

export const pageQuery = graphql`
  query loginPageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
