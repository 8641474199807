import React from 'react'
import styles from './tabs.module.scss'
import { navigate } from 'gatsby'

interface TabProps {
  tab: string
  route: string // URI, not route name
  active: boolean
}
interface TabsProps {
  tabsArray: TabProps[]
}

const Tabs: React.FC<TabsProps> = ({ tabsArray }) => (
  <div className={styles.tabs_container}>
    {tabsArray.map(({ tab, route, active }, index) => {
      return (
        <div
          key={index}
          onClick={() => navigate(route)}
          className={
            active ? `${styles.tab} ${styles.tab_active}` : styles.tab
          }>
          <span>{tab}</span>
        </div>
      )
    })}
  </div>
)

export default Tabs
