import React, { useReducer } from 'react'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { t } from '../../i18n'
import logic from '../../logic'
import SelectorFooterDesktop from '../FunnelSelector/Footers/SelectorFooterDesktop'
import InputCesta from '../inputCesta/InputCesta'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './RecoverPassword.module.scss'

interface Props {
  recoverPasswordStep: number
  changeStep: React.Dispatch<React.SetStateAction<number>>
  setLoginStep: (step: number) => void
}

interface PasswordRecoveryData {
  email: string
}
const PasswordRecovery = yup.object().shape({
  email: yup
    .string()
    .email(t('validations.register.format', { label: t('formLabels.email') }))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
})
function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST_PASSWORD':
      return {
        ...state,
        loading: true,
      }
    case 'REQUEST_PASSWORD_OK':
      return {
        ...state,
        success: true,
        loading: false,
        email: action.payload.email,
      }
    case 'REQUEST_PASSWORD_FAILED':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        success: false,
      }
    case 'RESET_SUCCESS':
      return {
        ...state,
        success: false,
      }
    case 'RESET_ERROR':
      return {
        ...state,
        error: false,
      }
    default:
      throw new Error()
  }
}

export default function RecoverPassword({
  recoverPasswordStep,
  changeStep,
  setLoginStep,
}: Props) {
  const [state, dispatch] = useReducer(reducer, {
    error: null,
    loading: false,
    success: false,
    email: null,
  })
  const { register, handleSubmit, errors } = useForm<PasswordRecoveryData>({
    validationSchema: PasswordRecovery,
  })

  const onSubmit = (data) => {
    const domain = window.location.hostname
    dispatch({
      type: 'REQUEST_PASSWORD',
    })
    logic
      .requestNewPassword(data.email, domain)
      .then((res) => {
        if (res.message === 'ok') {
          dispatch({
            type: 'REQUEST_PASSWORD_OK',
            payload: {
              email: res.email,
            },
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: 'REQUEST_PASSWORD_FAILED',
          payload: {
            error: err.message,
          },
        })
      })
  }

  if (state.success) {
    dispatch({
      type: 'RESET_SUCCESS',
    })
    changeStep(2)
  }

  if (state.error) {
    dispatch({
      type: 'RESET_ERROR',
    })
    changeStep(2)
  }

  return (
    <div className={styles.recover_password_cont}>
      {recoverPasswordStep === 1 && !state.loading && (
        <div className={styles.recover_email}>
          <p>{t('login.recuperar_contraseña')}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputCesta
              label={t('formLabels.email')}
              name="email"
              refe={register}
              errors={errors}
            />
            <p className={styles.recover_pass}>
              {t('login.iniciar_sesion')}{' '}
              <a
                onClick={() => setLoginStep(0)}
                className={styles.bold}
                href="#">
                {t('validations.login.passwordClick')}
              </a>
            </p>
            <SelectorFooterDesktop continueButton />
          </form>
        </div>
      )}
      {recoverPasswordStep === 1 && state.loading && (
        <SpinnerWithText text={t('login.cargando')} />
      )}
      {recoverPasswordStep === 2 && (
        <div className={styles.recover_email}>
          <p>
            {t('validations.login.passwordEmail1')} {state.email}.{' '}
            {t('validations.login.passwordEmail2')}
          </p>
          <p className={styles.recover_pass}>
            {t('login.iniciar_sesion')}{' '}
            <a onClick={() => setLoginStep(0)} className={styles.bold} href="#">
              {t('validations.login.passwordClick')}
            </a>
          </p>
        </div>
      )}
    </div>
  )
}
