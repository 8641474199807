import React from 'react'
import styles from './index.module.scss'
import UserIcon from '../../assets/user.svg'
import { FormattedMessage } from 'react-intl'

interface Props {
  loginBarType: 'register' | 'login'
  changeLoginbarType: (type: 'register' | 'login') => void
}
export default function LoginBar({ loginBarType, changeLoginbarType }: Props) {
  const textOneId =
    loginBarType === 'register'
      ? 'registerForm.login_bar_text_one'
      : 'registerForm.register_bar_text_one'
  const textTwoId =
    loginBarType === 'register'
      ? 'registerForm.login_bar_text_two'
      : 'registerForm.register_bar_text_two'

  if (!loginBarType) {
    return null
  }

  return (
    <div className={styles.login_bar}>
      <p>
        <UserIcon className={styles.login_icon} />
        <FormattedMessage id={textOneId} />{' '}
        <a
          onClick={() =>
            changeLoginbarType(
              loginBarType === 'register' ? 'login' : 'register'
            )
          }
          className={styles.login_link}>
          <FormattedMessage id={textTwoId} />
        </a>
      </p>
    </div>
  )
}
