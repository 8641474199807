import React, { useContext, useEffect, useState } from 'react'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import * as UserActions from '../../context/actions/userActions'
import AppContext from '../../context/context'
import { t } from '../../i18n'
import RecoverPassword from '../RecoverPassword/RecoverPassword'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import { loginFields } from './fieldValidations'
import styles from './loginContainer.module.scss'
import LoginForm from './LoginForm'
import SelectorFooterDesktop from '../FunnelSelector/Footers/SelectorFooterDesktop'
import LoginBar from '../LoginBar/LoginBar'

const LoginSchema = yup.object().shape(loginFields)

type LoginFields = { [k in keyof typeof loginFields]: string }

interface Props {
  onLogin: () => void
  preFillEmail?: string
  userIdentified?: boolean
  loginBarType?: 'register' | 'login' | null
  changeLoginbarType?: (type: 'register' | 'login') => void
  onGoBack?: () => void
  hideLoginBar?: boolean
  neumaticosEncontrados?: number
  footer?: (props: {}) => JSX.Element
  footerData?: {
    [key: string]: string | number
  }
}
const LoginContainer = ({
  onLogin,
  preFillEmail,
  userIdentified,
  loginBarType,
  changeLoginbarType,
  onGoBack,
  hideLoginBar,
  neumaticosEncontrados,
  footer,
  footerData,
}: Props) => {
  const { userInfo, dispatch, cestaState } = useContext(AppContext)
  const { register, handleSubmit, errors } = useForm<LoginFields>({
    validationSchema: LoginSchema,
  })
  const [recoverPasswordStep, setRecoverPasswordStep] = useState(1)
  const [onLoginCalled, setOnLoginCalled] = useState(false)
  const [step, setStep] = useState(0)

  const cestaID = cestaState.products.length ? cestaState.cestaID : null

  const onSubmit = (data: LoginFields) => {
    dispatch(UserActions.login(data.email, data.password, cestaID))
  }

  useEffect(() => {
    dispatch(UserActions.setUserErrorNull())
  }, [])

  if (userInfo.loading) {
    return <SpinnerWithText text={t('login.cargando')} />
  }

  if (userInfo.loggedIn) {
    if (!onLoginCalled) {
      onLogin()
      setOnLoginCalled(true)
    }
    return null
  }

  const changeStep = (step) => {
    setStep(step)
  }

  return (
    <React.Fragment>
      {step === 0 && (
        <form className={styles.login_form} onSubmit={handleSubmit(onSubmit)}>
          {!hideLoginBar ? (
            <LoginBar
              changeLoginbarType={changeLoginbarType}
              loginBarType={loginBarType}
            />
          ) : null}
          <div className={styles.main_login_content}>
            <p className={styles.intro_text}>
              {!userIdentified
                ? t('registerForm.intro_text')
                : t('preferences.login-header')}
            </p>
            <LoginForm
              setStep={changeStep}
              formRef={register}
              errors={errors}
              onLogin={onLogin}
              preFillEmail={preFillEmail}
            />
          </div>
          {footer ? (
            footer(footerData)
          ) : (
            <SelectorFooterDesktop
              onGoBack={onGoBack}
              count={neumaticosEncontrados}
              continueButton
            />
          )}
        </form>
      )}
      {step === 1 && (
        <React.Fragment>
          {!hideLoginBar ? (
            <LoginBar
              changeLoginbarType={changeLoginbarType}
              loginBarType={loginBarType}
            />
          ) : null}
          <RecoverPassword
            setLoginStep={changeStep}
            changeStep={setRecoverPasswordStep}
            recoverPasswordStep={recoverPasswordStep}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default LoginContainer
